/* This file is meant for required styled that in www-web were applied through some other mechanism than Ragnar.
To smoothen the migration its easiest to keep them here in a CSS file for now, but they can (should?) be rewritten
to Ragnar styles when the migration is done. */

.application {
  width: 100%;
  height: 100%;
}

.application img {
  max-width: 100%;
}
.application img {
  border-style: none;
}

body {
  backface-visibility: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "MentiText", Arial, sans-serif;
}
